<template>
	<modal-lateral ref="filter" titulo="Filtro">
		<div class="row mt-4 mx-3 gap-4">
			<div class="col-12">
				<p class="f-10 ml-2">Equipo</p>
				<el-select v-model="filters.id_equipo" class="w-100">
					<el-option 
					v-for="(item, index) in listaEquipos" 
					:key="index" 
					:value="item.id"
					:label="item.equipo"
					/>
				</el-select>
			</div>
			<div class="col-12">
				<p class="f-10 ml-2">Cliente</p>
				<el-select v-model="filters.id_cliente" class="w-100">
					<el-option v-for="(item, index) in listaClientes" 
					:key="index" 
					:value="item.id"
					:label="item.cliente"
					/>
				</el-select>
			</div>
			<div class="col-12">
				<p class="f-10">Rango de fecha de venta</p>
				<div class="d-flex gap-3">
					<el-date-picker
                        v-model="rangoFecha"
                        type="daterange"
                        range-separator="a"
                        size="small"
                        start-placeholder="Fecha Inicio"
                        value-format="yyyy-MM-dd"
                        class="w-100 mt-1"
                        end-placeholder="Fecha Fin">
                    </el-date-picker>
				</div>
			</div>
			<div class="col-12">
				<p class="f-10 ml-2">Rango de precio</p>
				<el-slider v-model="rangoPrecio" :min="listaRangos.valor_min" :max="listaRangos.valor_max+1" range :format-tooltip="formatTooltipRange"></el-slider>
			</div>
		</div>
		<div class="botones">
			<button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilter">
                Limpiar
            </button>
            <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filterEquipos">
                Filtrar
            </button>
		</div>
	</modal-lateral>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
	data(){
		return {

		}
	},
	async created(){},
	computed: {
		filters:{
			get(){ return this.filter },
			set(val){ this.set_filter(val) }
		},

		rangoPrecio:{
			get(){ return [this.filters.precio_minimo, this.filters.precio_maximo] },
			set(val){ 
				this.filters.precio_minimo = val[0]
				this.filters.precio_maximo = val[1]
			}
		},
		rangoFecha:{
			get(){ return this.filters.fecha_venta_desde == null ? null : [this.filters.fecha_venta_desde, this.filters.fecha_venta_hasta] },
			set(val){ 
				this.filters.fecha_venta_desde = val == null ? null : val[0]
				this.filters.fecha_venta_hasta = val == null ? null : val[1]
			}
		},

		...mapGetters({
            listaEquipos: 'ventas/presencia_equipos/listaEquipos',
            listaClientes: 'ventas/presencia_equipos/listaClientes',
            filter: 'ventas/presencia_equipos/filter',
            listaRangos: 'ventas/presencia_equipos/listaRangos',
        }),
	},
	methods: {
		...mapActions({
            Action_clean_filter: 'ventas/presencia_equipos/Action_clean_filter',
            Action_get_presencia_equipos: 'ventas/presencia_equipos/Action_get_presencia_equipos',
        }),
		...mapMutations({
			set_filter: 'ventas/presencia_equipos/set_filter'
		}),
		toggle(){
			this.$refs.filter.toggle()
		},
		formatTooltipRange(val){
			return this.formatoMoneda(val)
		},
		clearFilter(){
			this.Action_clean_filter()
			this.$emit('listarPresenciaEquipos')
		},
		filterEquipos(){
			this.$refs.filter.toggle()
			this.$emit('listarPresenciaEquipos')
		}
	}
}
</script>